export default {
  'comfort': 'Comfort',
  'comfortable': 'Comfortable',
  'intermediary': 'Intermediary',
  'critical': 'Critical',
  'lethal': 'Lethal',
  'no_data': 'No Data',
  'aviaries': 'Aviaries',
  'aviary': 'Aviary',
  'loading_properties': 'Loading properties',
  'none_found': 'No properties were found',
  'empty_batch': 'Empty Batch',
  'pre_lodged': 'Pre-lodge',
  'lodged': 'Lodged',
  'slaughter': 'Slaughter',
  'priority': 'Priority',
  'filtros': 'Filters',
  'aviaries_page': 'Aviaries per Page',
  'rations_page': 'Rations per Page',
  'stage': 'Stage',
  'shed': 'Shed',
  'lineage': 'Lineage',
  'sex': 'Sex',
  'age': 'Age',
  'real_age': 'Chickens age',
  'batch_age': 'Batch Age',
  'weight': 'Weight',
  'dwg': 'D.W.G',
  'uniformity': 'Uniformity',
  'performance': 'Performance',
  'producer': 'Producer',
  'city': 'City',
  'greater_than': 'Greater than',
  'less_than': 'Less than',
  'exactly': 'Exactly',
  'add': 'Add',
  'remove': 'Remove',
  'and': 'AND',
  'or': 'OR',
  'identification': 'Identification',
  'address': 'Address',
  'ambience': 'Ambience',
  'type': 'Type',
  'alerts': 'Alerts',
  'message': 'Message',
  'conversionFactor': 'Conversion Factor',
  'creation_date': 'Creation Date',
  'target_weight': 'Target Weight',
  'target_value': 'Ration necessary',
  'qty24h': 'Last 24h',
  'subtype': 'Subtype',
  'bed_temperature': 'Bed Temperature',
  'water_temperature': 'Water Temperature',
  'ambient-humidity': 'Ambient Humidity',
  'entry_weight': 'Entry Weight',
  'leave_weight': 'Exit Weight',
  'leave_age': 'Exit Age',
  'ambient-temperature': 'Ambient Temperature',
  'humidity-ambient-temperature': 'Ambient Humidity and Temperature',
  'contact': 'Contact',
  'simulation': 'Simulation',
  'alertTypeQty': 'Types',
  'weekly_weight_projection': 'Weight Projection by Age',
  'totalQty': 'Quantity',
  'alerts/recent/': 'Alerts',
  'alerts/history/': 'Alerts History',
  'sub_region': 'Sub-region',
  'lodge': 'Lodge',
  'current_lodge': 'Current Lodge',
  'date': 'Date',
  'effective_weight': 'Effective Weight',
  'nutrition': 'Nutrition',
  'sanity': 'Sanity',
  'logout': 'Logout',
  'loading': 'Loading',
  'en': 'English',
  'es': 'Spanish',
  'pt-br': 'Portuguese',
  'slaughter_forecast': 'Slaughter Forecast',
  'close': 'Close',
  'last_projected_weight': 'Last Forecast Weight',
  'actions': 'Actions',
  'predicted_weight': 'Predicted Weight',
  'select_a_date': 'Select a Date',
  'days': 'days',
  'production_days': 'Production days',
  'weeks': 'weeks',
  'production_weeks': 'Production weeks',
  'production_time': 'Production Time',
  'prediction': 'Forecast',
  'predictions': 'Forecasts',
  'fwu': 'F.W.U',
  'search': 'Search',
  'broiler_chicken': 'Broiler Chicken',
  'headquarters': 'headquarters',
  'home': 'Home',
  'status': 'Status',
  'silo': 'Silo',
  'ration': 'Ration',
  'capacity': 'Capacity',
  'free': 'Free',
  'available': 'Available',
  "last_received_time": 'Last Reading',
  'occupation': 'Occupation',
  'expected_end_date': 'Expected End Date',
  'full': 'Full',
  'low': 'Low',
  'satisfactory': 'Satisfactory',
  'last_ration_delivered': 'Last Ration Delivered',
  'ration_delivered': 'Ration Delivered',
  'consumed': 'Consumed',
  'pre_initial': 'Pre-Initial',
  'initial': 'Initial',
  'growth': 'Growth',
  'general': 'General',
  'male': 'Male',
  'female': 'Female',
  'production': 'Production',
  'hatch': 'Hatch',
  'production_type': 'Production Type',
  'aerosacculitis': 'Aerosacculitis',
  'contaminated': 'Condemned Birds',
  'total_delivered_ration': 'Total Delivered',
  'total_consumed': 'Total Consumed',
  'core': 'Core',
  'pcp': 'PCP Planning',
  'pcp_filter': 'PCP',
  'dwg_filter': 'DWG',
  'weighing': 'Weighing',
  'StorageCapacity': 'Storage capacity',
  'TotalFeedInTheField': 'Total feed in the field',
  'average_consumption_per_annimal': 'Average consumption per individual',
  'TotalFree': 'Total free',
  'nutritionTotals': 'Totals',
  'total_free': 'Total Available (kg)',
  'week' : 'Week',
  'age_range': 'Age range',
  'total_delivered': 'Total delivered',
};
